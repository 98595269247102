import axios from 'axios';
import {
    AUTH_LOGIN,
    AUTH_LOGIN_SUCCESS,
    AUTH_LOGIN_FAILURE,
    AUTH_GET_STATUS,
    AUTH_GET_STATUS_SUCCESS,
    AUTH_GET_STATUS_FAILURE,
    AUTH_LOGOUT
} from "./ActionTypes";
import Cookies from 'universal-cookie';
const API_SERVER = 'https://api.swichee.me/';

// LOGIN
// export function loginRequest(username, password) {
//     return (dispatch) => {
//         // inform login api is starting
//         dispatch(login());
//
//         // api request
//         return axios.post(API_SERVER + 'account/login?type=id&ver=0.3', {
//                 id: username, password: password
//             },{withCredentials:true}
//         )
//             .then((response) => {
//                 console.log('login response: ', response);
//                 if (response.data.code === 200) {
//                     dispatch(loginSuccess(username));
//                 } else {
//                     dispatch(loginFailure());
//                 }
//             }).catch((error) => {
//                 // failed
//                 dispatch(loginFailure());
//             });
//     };
// }

const remapLoginResponseData = data => {

    //TODO
    //Remap response data
};
export function handleLogin(type,data) {
    return (dispatch) => {
        // inform login api is starting

        switch (type) {
            case 'fb':
                console.log(data);
                return axios.post(API_SERVER + 'account/login?type=fb&ver=0.3', {
                        idToken: data.Zi.id_token,
                        accessToken: data.accessToken
                    }, {withCredentials: true}
                ).then((response) => {
                    let data = response.data.data;
                    data.name = response.data.data.nickname;
                    data.nickname = response.data.data.id;
                    console.log('login response: ', response);
                    if (response.data.code === 200) {
                        dispatch(getUserStatus(data));
                        let cookies = new Cookies();
                        data.image && cookies.set('pr_img',data.image,{path:'/',expires:new Date(Date.now()+864000000)});
                        data.nickname && cookies.set('pr_nn',data.nickname,{path:'/',expires:new Date(Date.now()+864000000)});
                        data.name && cookies.set('pr_n',data.name,{path:'/',expires:new Date(Date.now()+864000000)});
                    } else {
                        console.log('else',response,type,data);
                        dispatch(loginFailure());
                    }
                }).catch((error) => {
                    // failed
                    dispatch(loginFailure());
                });
            case 'google':
                return axios.post(API_SERVER + 'account/login?type=google&ver=0.3', {
                        idToken: data.Zi.id_token,
                        accessToken: data.Zi.access_token
                    }, {withCredentials: true}
                ).then((response) => {
                    let data = response.data.data;
                    data.name = response.data.data.nickname;
                    data.nickname = response.data.data.id;
                    console.log('login response: ', response);
                    if (response.data.code === 200) {
                        dispatch(getUserStatus(data));
                        let cookies = new Cookies();
                        data.image && cookies.set('pr_img',data.image,{path:'/',expires:new Date(Date.now()+864000000)});
                        data.nickname && cookies.set('pr_nn',data.nickname,{path:'/',expires:new Date(Date.now()+864000000)});
                        data.name && cookies.set('pr_n',data.name,{path:'/',expires:new Date(Date.now()+864000000)});
                    } else {
                        console.log('else',response,type,data);
                        dispatch(loginFailure());
                    }
                }).catch((error) => {
                    // failed
                    dispatch(loginFailure());
                });
            case 'id':
                return axios.post(API_SERVER + 'account/login?type=id&ver=0.3', {
                        id: data.id, password: data.password
                    },{withCredentials:true}
                )
                    .then((response) => {
                        let data = response.data.data;
                        data.name = response.data.data.nickname;
                        data.nickname = response.data.data.id;
                        console.log('login response: ', response);
                        if (response.data.code === 200) {
                            dispatch(getUserStatus(data));
                            let cookies = new Cookies();
                            data.image && cookies.set('pr_img',data.image,{path:'/',expires:new Date(Date.now()+864000000)});
                            data.nickname && cookies.set('pr_nn',data.nickname,{path:'/',expires:new Date(Date.now()+864000000)});
                            data.name && cookies.set('pr_n',data.name,{path:'/',expires:new Date(Date.now()+864000000)});
                        } else {
                            console.log('else',response,type,data);
                            dispatch(loginFailure());
                        }
                    }).catch((error) => {
                    // failed
                    dispatch(loginFailure());
                });
            default:
                console.log('default login request');
                dispatch(loginFailure());
        }
        // api request
    };
}

export function login() {
    return {
        type: AUTH_LOGIN
    };
}

export function loginSuccess(username) {
    return {
        type: AUTH_LOGIN_SUCCESS,
        username
    };
}

export function loginFailure() {
    return {
        type: AUTH_LOGIN_FAILURE
    };
}

// GET STATUS
export function getStatusRequest() {
    return (dispatch) => {
        // dispatch(getStatus());
        return axios.post(API_SERVER + 'account/metaData',{}, {withCredentials: true})
            .then((response) => {
                console.log('get status log: ', response);
                if (response.data.code === 200) {
                    dispatch(getUserStatus(response.data.data));
                    let cookies = new Cookies();
                    response.data.data.image && cookies.set('pr_img',response.data.data.image,{path:'/',expires:new Date(Date.now()+864000000)});
                    response.data.data.nickname && cookies.set('pr_nn',response.data.data.nickname,{path:'/',expires:new Date(Date.now()+864000000)});
                    response.data.data.name && cookies.set('pr_n',response.data.data.name,{path:'/',expires:new Date(Date.now()+864000000)});
                    console.log('userstatus',new Date(Date.now()+172800),Date.now());
                    // dispatch(getStatusSuccess(response.data.data.username));
                    // console.log('success');
                    // dispatch(loginSuccess(response.data.data.username));
                    // console.log('loginsuccess');
                } else {
                    let cookies = new Cookies();
                    cookies.remove('pr_img');
                    cookies.remove('pr_nn');
                    cookies.remove('pr_n');
                    dispatch(getStatusFailure());
                }
            }).catch((error) => {
                dispatch(getStatusFailure());
            });
    };
}

// export function getStatus() {
//     return {
//         type: AUTH_GET_STATUS
//     };
// }
//
// export function getStatusSuccess(username) {
//     return {
//         type: AUTH_GET_STATUS_SUCCESS,
//         username
//     };
// }

export function getUserStatus(data) {
    return {
        type: AUTH_GET_STATUS_SUCCESS,
        data: data
    };
}

export function getStatusFailure() {
    return {
        type: AUTH_GET_STATUS_FAILURE
    };
}

export function logoutRequest() {
    return (dispatch) => {
        return axios.post(API_SERVER + 'account/logout',{}, {withCredentials: true})
            .then((response) => {
                dispatch(logout());
                let cookies = new Cookies();
                cookies.remove('pr_img');
                cookies.remove('pr_nn');
                cookies.remove('pr_n');
            });
    };
}

export function logout() {
    return {
        type: AUTH_LOGOUT
    };
}
