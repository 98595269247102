import React, {Component} from 'react';
import {Form, Row, Col, Divider, Upload, Select, Icon, Modal, Switch} from 'antd';
import axios from "axios";

const {Option} = Select;

const uploadButton = (
    <div style={{cursor: 'pointer'}}>
        <Icon type="plus"/>
        <div className="upload-button-text">업로드</div>
    </div>
);
const category = [
    "No Choice",
    "Funding",
    "Music",
    "Sports",
    "Gaming",
    "Comedy",
    "Entertainment",
    "News & Politics",
    "Education",
    "Pets & Animals",
    "Travel & Events",
    "Science & Technology",
    "People & Blogs",
    "Fashion & Style",
    "Autos & Vehicles",
    "Film & Animation",
    "Food & Recipe",
    "Webtoon",
    "Daily"
];

class BlogThird extends Component {
    constructor(props) {
        super(props);

        this.state = {
            previewVisible: false,
            previewImage: '',
            folders: [],
            thumbnailImage: [],
            selectedFolder: ''
        };
        axios.post('https://api.swichee.me/folder/list', {}, {withCredentials: true})
            .then((response) => {
                console.log(response);
                this.setState({folders: response.data, selectedFolder: response.data[0].folder_idx});
                this.props.form.setFieldsValue({folder_idx: response.data[0].folder_idx});
            }).catch((e) => console.log(e));

        this.handleFolder.bind(this);
    }

    handleCancel = () => {
        this.setState({previewVisible: false});
        console.log(this)
    };

    handleThumbnailImageChange = ({fileList}) => {
        this.setState({thumbnailImage: fileList});
    };

    getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await this.getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
        });
    };

    categoryRender() {
        return category.map((data, index) =>
            <Option key={index}>{data}</Option>
        );
    }

    folderRender() {
        return this.state.folders.map((data) =>
            <Option key={data.folder_idx}>{data.name}</Option>
        );
    }

    handleFolder(value) {
        this.props.form.setFieldsValue({folder_idx: value});

    }

    render() {
        const {previewVisible, previewImage, fileList} = this.state;
        const {getFieldDecorator} = this.props.form;
        const thumbnailUploadProps = {
            listType: 'picture',
            multiple: false,
        };
        console.log(this.state);
        return (
            <div>
                <Row gutter={24}>
                    <Col span={24}>
                        <Divider>썸네일 업로드</Divider>
                        <Form.Item>
                            {getFieldDecorator("thumbnail")(
                                <Upload {...thumbnailUploadProps} accept={'image/jpeg, image/png, image/gif'}
                                        onChange={this.handleThumbnailImageChange}
                                        onPreview={this.handlePreview}
                                        beforeUpload={(file) => {
                                            return false;
                                        }}
                                >
                                    {this.state.thumbnailImage.length >= 1 ? null : uploadButton
                                    }
                                </Upload>
                            )}
                            <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                                <img alt="example" style={{width: '100%'}} src={previewImage}/>
                            </Modal>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Divider>카테고리 설정</Divider>
                        <Form.Item>
                            {getFieldDecorator("category_idx", {
                                initialValue: "0"
                            })(
                                <Select style={{width: 250}}>
                                    {this.categoryRender()}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Divider>폴더 설정</Divider>
                        <Form.Item>
                            {getFieldDecorator("folder_idx")(
                                <Select style={{width: 250}}>
                                    {this.state.folders ? this.folderRender() : null}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Divider>3시간동안 무료로 공개</Divider>
                        {getFieldDecorator("paidConversion", {
                            checked: true,
                        })(
                            <Switch defaultChecked checkedChildren="무료" unCheckedChildren="유료"/>
                        )}
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Form.create()(BlogThird);
