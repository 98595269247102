import React, {Component} from 'react';
import {Upload, Icon, Form, Button, Input, Divider, Row, Col, Radio} from "antd";
// import 'video.js/dist/video-js.css';
import '../../public/wave-style.css';
import videojs from "video.js";
import WaveSurfer from 'wavesurfer.js/dist/wavesurfer';
// import Dropzone from 'react-dropzone'




/*
// the following imports are only needed when you're using
// the microphone plugin
import 'webrtc-adapter';

import MicrophonePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.microphone.js';
WaveSurfer.microphone = MicrophonePlugin;
*/

// register videojs-wavesurfer plugin with this import
// import 'videojs-wavesurfer/dist/css/videojs.wavesurfer.css';
import Wavesurfer from 'videojs-wavesurfer/dist/videojs.wavesurfer.js';
const { Dragger } = Upload;
const {TextArea} = Input;

class SoundFirst extends Component {
    constructor(props) {
        super(props);
        // console.log('videofirst props', props);

        this.state = {
            audio: [],
            art:[],
            // albumArt:'url(favicon.ico)'
            albumArt:null
        };
        // this.player = videojs(this.videoNode, this.props, function onPlayerReady() {
        //     console.log('onPlayerReady', this)
        // });

    }

    componentDidMount() {
        this.player = videojs(this.audioNode, {
            controls: true,
            autoplay: false,
            loop: false,
            fluid: false,
            width: 600,
            height: 200,
            controlBar: {
                fullscreenToggle: false
            },

            plugins: {
                // wavesurfer: {
                //         src: '7dc0d0f12d379da6b07aca5b743eb3b9.mp3',
                //
                //     // src: 'media/hal.wav',
                //     msDisplayMax: 10,
                //     debug: true,
                //     waveColor: 'grey',
                //     progressColor: 'black',
                //     cursorColor: 'black',
                //     hideScrollbar: true
                // }
                wavesurfer: {
                    // src: '7dc0d0f12d379da6b07aca5b743eb3b9.mp3',
                    msDisplayMax: 10,
                    debug: true,
                    waveColor: '#163b5b',
                    progressColor: '#f90',
                    cursorColor: '#bbb',
                    barWidth: '3',
                    hideScrollbar: true
                }
            }
        }, () => {
            // print version information at startup
            let version_info = 'Using video.js ' + videojs.VERSION +
                ' with videojs-wavesurfer ' + videojs.getPluginVersion('wavesurfer') +
                ' and wavesurfer.js ' + WaveSurfer.VERSION;
            videojs.log(version_info);
        });
        this.player.on('waveReady', (event) => {
            console.log('waveform: ready!');
        });

        this.player.on('playbackFinish', (event) => {
            console.log('playback finished.');
        });

        // error handling
        this.player.on('error', (element, error) => {
            console.error(error);
        });
        // // instantiate Video.js
        // this.player = videojs(this.audioNode, this.props, function onPlayerReady() {
        //     console.log('onPlayerReady', this)
        // });
        // this.player.
        // var track = new videojs.AudioTrack({
        //     id: 'my-spanish-audio-track',
        //     kind: 'translation',
        //     label: 'Spanish',
        //     language: 'es'
        // });
        // // this.player.src(["https://5aa40ff9df62d.streamlock.net/vods3/_definst_/mp4:swichee/swichee-seoul/MudgDS/video/4/fb2fd232fea62b10a731a1cb934ed63b1cc522f8fa0639f21134abbdcbebb057.mp4/playlist.m3u8?swichee_tokenCustomParameter=f3c91b&swichee_tokenhash=d8ccf0e5033f7ee42452f7d9eb738e7c44e965a3d3526511d8ca9709e1e0192b3a8bc985e7ba6e83f9cb433071f65c0224d1278b948e09413aa12aabd4d7f350","https://5aa40ff9df62d.streamlock.net/vods3/_definst_/mp4:swichee/swichee-seoul/MudgDS/video/4/fb2fd232fea62b10a731a1cb934ed63b1cc522f8fa0639f21134abbdcbebb057.mp4/manifest.mpd?swichee_tokenCustomParameter=f3c91b&swichee_tokenhash=d7cb15e64292dc664ceb8d32a56b21e6e7321293b4e78149d599603552776a291df1bf72ca3ce4a442efd45c0ef7e3c257c5fa8aa39c46999d11feb866337457"]);
    }
    componentWillUnmount() {
        if (this.player) {
            this.player.dispose();
        }
    }

    handleChange = info => {
    };

    onRadioChange = e => {
        console.log(e.target.value);
    };

    getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    handleFileList = info => {
        console.log(this.props,info);
        console.log(this.props.form.getFieldValue('album_art'));
    };

    handleFileRemove = info => {
        console.log(this.props);
        this.setState({ art:[] });
    };

    normFile = e => {
        let fileList = e.fileList.slice(-1);
        if(fileList.length === 0){
            this.setState({albumArt:null});
        }
        if (Array.isArray(e)) {
            return e;
        }
        return e && fileList;
    };

    render() {
        // const albumImageUploadProps = {
        //     name: 'album-image',
        //     action: '',
        //     onChange: this.handleChange,
        //     showUploadList: false,
        //     listType: 'picture-card',
        //     className: 'album-image-uploader',
        // };
        const audioUploadProps = {
            action: '',
            onChange: this.handleChange,
            multiple: false,
        };
        const {getFieldDecorator} = this.props.form;

        const draggerProps = {
            name: 'album_art',
            multiple: false,
            // action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
            onChange:this.handleFileList,
            // onRemove:this.handleFileRemove
            // onChange(info) {
            //     console.log(this);
            //     // this.setState({art:info.fileList});
            //     const { status } = info.file;
            //     if (status !== 'uploading') {
            //         console.log(info.file, info.fileList);
            //     }
            //     if (status === 'done') {
            //         message.success(`${info.file.name} file uploaded successfully.`);
            //     } else if (status === 'error') {
            //         message.error(`${info.file.name} file upload failed.`);
            //     }
            // },
        };

        return (
            <div>
                <Row gutter={24}>
                    {getFieldDecorator("album_art",{
                        valuePropName: 'fileList',
                        getValueFromEvent: this.normFile,
                    })(
                    <Dragger {...draggerProps}
                        multiple={false}
                             accept={'image/jpeg, image/png'}

                             // fileList={this.props.form.getFieldValue('album_art')}
                             beforeUpload={(file) => {
                                 let reader = new FileReader();
                                 reader.readAsDataURL(file);
                                 reader.onloadend = function () {
                                     console.log(reader);
                                     // this.player.src({type: file.type, src: reader.result});
                                     this.setState({albumArt:reader.result});
                                     console.log(this);
                                 }.bind(this);
                                 return false;
                             }}
                    >
                        {
                            this.state.albumArt?
                                <div style={{marginBottom:20, marginLeft:'auto',marginRight:'auto',border: '1px solid #d9d9d9', backgroundColor:'#f9f9f9', display: 'inline-block'}}>
                                    <img alt={'album art'} style={{
                                        objectFit:'contain',
                                        width: 400,
                                        height: 400}} src={this.state.albumArt}/>
                                </div>
                                :
                                <div>
                                    <p className="ant-upload-drag-icon">
                                        <Icon type="inbox" />
                                    </p>
                                    <p className="ant-upload-text">앨범아트를 선택해주세요!</p>
                                    <p className="ant-upload-hint">
                                        가로세로 사이즈 최소 500px
                                    </p>
                                </div>

                        }


                        {/*<p className="ant-upload-hint">*/}
                        {/*    Support for a single or bulk upload. Strictly prohibit from uploading company data or other*/}
                        {/*    band files*/}
                        {/*</p>*/}
                    </Dragger>
                    )}
                    <Divider/>
                    <div style={{marginTop:20, marginLeft: 'auto',marginRight: 'auto'}}>
                    <div style={{marginLeft: 'auto',marginRight: 'auto'}} data-vjs-player>
                        <audio controls ref={node => this.audioNode = node} className="video-js vjs-wave-skin vjs-big-play-centered " style={{backgroundColor:"#fef", zIndex:'0'}} />
                    </div>
                    </div>
                    <Col span={12}>
                        {/*<Divider>앨범 이미지 업로드</Divider>*/}
                        {/*<Form.Item>*/}
                        {/*    {getFieldDecorator("sound-file")(*/}
                        {/*        <Upload {...videoUploadProps} fileList={this.state.audio}*/}
                        {/*                accept={'audio/aac, audio/mp3'}*/}
                        {/*                beforeUpload={(file) => {*/}
                        {/*                    let reader = new FileReader();*/}
                        {/*                    reader.readAsDataURL(file);*/}
                        {/*                    reader.onloadend = function () {*/}
                        {/*                        console.log(reader);*/}
                        {/*                        this.player.src({type: file.type, src: reader.result});*/}
                        {/*                    }.bind(this);*/}
                        {/*                    return false;*/}
                        {/*                }}*/}
                        {/*        >*/}
                        {/*            <Button>*/}
                        {/*                <Icon type="upload"/> 업로드*/}
                        {/*            </Button>*/}
                        {/*        </Upload>*/}
                        {/*    )}*/}
                        {/*</Form.Item>*/}
                        <Divider>사운드 업로드</Divider>
                        <Form.Item>
                            {getFieldDecorator("sound")(
                                <Upload {...audioUploadProps}
                                    fileList={this.state.audio}
                                    accept={'audio/aac, audio/mp3, audio/m4a'}
                                    beforeUpload={(file) => {
                                    let reader = new FileReader();
                                    reader.readAsDataURL(file);
                                    reader.onloadend = function () {
                                    console.log(reader);
                                    this.player.wavesurfer().load(reader.result);
                                }.bind(this);
                                    return false;
                                }}
                                > <Button>
                                    <Icon type="upload"/> 업로드
                                </Button>
                                </Upload>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Divider>미리보기 시간 조정</Divider>
                        <Form.Item>
                            {getFieldDecorator("sampleSize", {initialValue: 'none'})(
                                <Radio.Group>
                                    <Radio.Button value="none">없음</Radio.Button>
                                    <Radio.Button value="5">5초</Radio.Button>
                                    <Radio.Button value="30">30초</Radio.Button>
                                </Radio.Group>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Divider>내용 입력</Divider>
                        <Form.Item>
                            {getFieldDecorator("title")(
                                <Input rows={6} placeholder="제목을 입력해주세요"/>
                            )}
                            {getFieldDecorator("content")(
                                <TextArea rows={6} placeholder="컨텐츠와 함게 들어갈 내용을 추가해보세요"/>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default SoundFirst;
