import React, {Component} from 'react';
import { Authentication } from '../components';

class MyPage extends Component {
    render() {
        return (
            <div>
                <Authentication mode={false} currentUser={this.props.currentUser} isLoggedIn={this.props.isLoggedIn}/>
            </div>
        );
    }
}

export default MyPage;