import React, {Component} from 'react';
import {Upload, Icon, Form, Button, Radio, Input, Divider, Row, Col, Tooltip} from "antd";
import videojs from 'video.js'
import "./videojs/video-js.min.css";
// import "./videojs/video-js-skin.css";

const {TextArea} = Input;

class VideoFirst extends Component {
    constructor(props) {
        super(props);
        console.log('videofirst props', props);

        this.state = {
            video: [],
            previewNone: true,
            preview5: true,
            preview30: true,
        };
        // this.player = videojs(this.videoNode, this.props, function onPlayerReady() {
        //     console.log('onPlayerReady', this)
        // });
    }

    componentDidMount() {
        // instantiate Video.js
        this.player = videojs(this.videoNode, {
            controls: true,
            autoplay: false,
            loop: false,
            fluid: false
        }, function onPlayerReady() {
            console.log('onPlayerReady', this)
        });
        // this.player.src(["https://5aa40ff9df62d.streamlock.net/vods3/_definst_/mp4:swichee/swichee-seoul/MudgDS/video/4/fb2fd232fea62b10a731a1cb934ed63b1cc522f8fa0639f21134abbdcbebb057.mp4/playlist.m3u8?swichee_tokenCustomParameter=f3c91b&swichee_tokenhash=d8ccf0e5033f7ee42452f7d9eb738e7c44e965a3d3526511d8ca9709e1e0192b3a8bc985e7ba6e83f9cb433071f65c0224d1278b948e09413aa12aabd4d7f350","https://5aa40ff9df62d.streamlock.net/vods3/_definst_/mp4:swichee/swichee-seoul/MudgDS/video/4/fb2fd232fea62b10a731a1cb934ed63b1cc522f8fa0639f21134abbdcbebb057.mp4/manifest.mpd?swichee_tokenCustomParameter=f3c91b&swichee_tokenhash=d7cb15e64292dc664ceb8d32a56b21e6e7321293b4e78149d599603552776a291df1bf72ca3ce4a442efd45c0ef7e3c257c5fa8aa39c46999d11feb866337457"]);
    }

    handleChange = data => {
        console.log(data);
    };

    render() {
        const videoUploadProps = {
            action: '',
            onChange: this.handleChange,
            multiple: false,

            previewFile(file) {
                console.log('Your upload file:', file);
            }
        };
        const {getFieldDecorator} = this.props.form;

        return (
            <div>

                <Row gutter={24}>
                    <div data-vjs-player style={{width: 640, height: 360, margin: '0 auto'}}>
                        <video controls ref={node => this.videoNode = node} className="video-js vjs-big-play-centered"
                               onLoadedMetadata={(data) => {
                                   if (this.player.duration() > 30) {
                                       this.setState({previewNone: false, preview5: false, preview30: false});
                                   } else if (this.player.duration() > 5) {
                                       this.setState({previewNone: false, preview5: false});
                                   } else {
                                       this.setState({previewNone: false});
                                   }
                               }}
                        />
                    </div>
                    <div style={{marginTop: 20, textAlign: 'right'}}>
                        <Tooltip title={renderProps => (
                            <div style={{textAlign: 'left'}}>
                                확인용도로 제공드리는 플레이어 입니다.
                                <br/>
                                일부 mov 파일의 경우 브라우저에서 재생지원이 되지않을수 있습니다.
                                <br/>
                                {
                                    this.state.tooltip ?
                                        <a
                                            onClick={() => this.setState({tooltip: false})}
                                            style={{color: "lightgray"}}
                                        >
                                            hevc (h.265) 코덱의 경우 화면재생이 원활하지 않을수있습니다.
                                            <br/>
                                            이경우 업로드시 h.264로 자동변환되어 모든 디바이스에서 재생이 가능해집니다.
                                        </a>
                                        :
                                        <div style={{textAlign: 'right'}}>
                                            <a
                                                onClick={() => this.setState({tooltip: true})}
                                                style={{color: "lightgray", textAlign: "right"}}
                                            >
                                                more
                                            </a>
                                        </div>
                                }
                            </div>
                        )}>
                            <Icon type="question-circle-o" style={{color: '#f90', marginRight: 30}}/>
                        </Tooltip>
                    </div>
                    <Col span={12}>
                        <Divider>비디오 업로드</Divider>
                        <Form.Item>
                            {getFieldDecorator("video")(
                                <Upload {...videoUploadProps}
                                        accept={'video/mp4, video/3gpp, video/3gp, video/quicktime'} listType='picture'
                                        previewFile={file => console.log(file)} onPreview={(props) => {
                                    console.log(props);
                                }} fileList={this.state.video}
                                        beforeUpload={(file) => {
                                            if(file.type==='video/quicktime'){
                                                this.setState({previewNone: false, preview5: false, preview30: false});
                                            }
                                            let reader = new FileReader();
                                            reader.readAsDataURL(file);
                                            reader.onloadend = function () {
                                                console.log(reader);
                                                this.player.src({type: file.type, src: reader.result});
                                            }.bind(this);
                                            return false;
                                        }}
                                >
                                    <Button>
                                        <Icon type="upload"/> 업로드
                                    </Button>
                                </Upload>
                            )}
                        </Form.Item>
                        <div style={{textAlign: 'right'}}>
                            <Tooltip title={renderProps => (
                                <div style={{textAlign: 'center'}}>mp4, mov, 3gp 파일을 지원합니다.<br/>2000Mb 제한</div>
                            )}>
                                <Icon type="question-circle-o" style={{color: '#f90', marginRight: 30}}/>
                            </Tooltip>
                        </div>

                    </Col>
                    <Col span={12}>
                        <Divider>미리보기 시간 조정</Divider>
                        <Form.Item>
                            {getFieldDecorator("sampleSize", {initialValue: 'none'})(
                                <Radio.Group
                                    onChange={() => console.log(this.player.duration() ? this.player.duration() : 'naan')}>
                                    <Radio.Button disabled={this.state.previewNone} value="none">없음</Radio.Button>
                                    <Radio.Button disabled={this.state.preview5} value="5">5초</Radio.Button>
                                    <Radio.Button disabled={this.state.preview30} value="30">30초</Radio.Button>
                                </Radio.Group>
                            )}
                        </Form.Item>
                        <div style={{textAlign: 'right'}}>
                            <Tooltip title={renderProps => (
                                <div style={{textAlign: 'center'}}>영상의 앞부분에서 추출됩니다.</div>
                            )}>
                                <Icon type="question-circle-o" style={{color: '#f90', marginRight: 30}}/>
                            </Tooltip>
                        </div>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Divider>내용 입력</Divider>
                        <Form.Item>
                            {getFieldDecorator("title")(
                                <Input rows={6} placeholder="제목을 입력해주세요"/>
                            )}
                            {getFieldDecorator("content")(
                                <TextArea rows={6} placeholder="비디오와 함게 들어갈 내용을 추가해보세요"/>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default VideoFirst;
