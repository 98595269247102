import React, {Component} from 'react';
import {Steps, Button, Form, Result, message, Progress} from 'antd';
import SoundFirst from "./SoundFirst";
import SoundSecond from "./SoundSecond";
import SoundThird from "./SoundThird";
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

import '../ContentStyle.css';
import axios from "axios";

const {Step} = Steps;

class UploadSound extends Component {
    constructor(props) {
        super(props);

        this.state = {
            current: 0,
            uploadState: null,
            uploadProgress: 0,
            uploadCancelled: false
        };
        console.log(props);
    }

    next() {
        const current = this.state.current + 1;
        this.setState({current});
    }

    prev() {
        const current = this.state.current - 1;
        this.setState({current});
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('받은 값: ', values);
                console.log('form data start making');
                let formData = new FormData();
                formData.append('content_type', 'sound');
                formData.append('folder_idx', values.folder_idx);
                formData.append('paidConversion',
                    'false'
                    //values.paidConversion
                );
                if (values.sampleSize !== 'none') {
                    formData.append('sampleSize',
                        values.sampleSize
                    );
                }
                formData.append('price', values.price * 10);
                formData.append('period', values.period);
                formData.append('title', values.title?values.title:'');
                formData.append('content', values.content?values.content:'');
                formData.append('category_idx', values.category_idx);
                formData.append('copyright_flag', 'true');
                console.log('form data 1st: ', formData);
                try {
                    if (values.album_art) {
                        formData.append('album_art', values.album_art[0].originFileObj, values.album_art[0].name);
                    }
                    if (values.thumbnail) {
                        formData.append('thumbnail', values.thumbnail.fileList[0].originFileObj, values.thumbnail.fileList[0].name);
                    }
                    if (!values.sound) {
                        message.error('Need Check sound file is valid', 4);
                        return;
                    }
                    formData.append('sound', values.sound.fileList[0].originFileObj, values.sound.fileList[0].name);
                } catch (e) {
                    console.log(e);
                }
                // console.log('form data 2nd: ', formData.);
                for (let value of formData.values()) {
                    console.log(value);
                }

                // this.setState({uploadState:true});
                axios.post('https://api.swichee.me/content/upload?ver=0.3', formData, {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: (progressEvent) => {
                        this.setState({
                            uploadState: 'normal',
                            uploadProgress: progressEvent.loaded / progressEvent.total * 100
                        });

                        if (progressEvent.loaded === progressEvent.total) {
                            message.success('인코딩 진행중', 3);
                        }
                        console.log(progressEvent);
                    }
                }).then((res) => {
                    if (res.data.code === 200) {
                        message.success('Success to Upload Content!!', 4);
                        this.props.history.push('/');

                    } else {
                        this.setState({uploadState: 'exception'});
                        message.error('Need Check Written values', 4);
                    }
                    console.log(res);
                }).catch((res) => {
                    console.log(res);
                    this.setState({uploadState: 'exception'});
                    message.error('Internal Server Error', 4);
                })
            }
        })
    };

    render() {
        const {current} = this.state;
        let steps = [
            {
                title: '사운드 업로드',
                content:
                    <SoundFirst form={this.props.form}/>
                ,
            },
            {
                title: '금액 및 기간 설정',
                content:
                    <SoundSecond form={this.props.form}/>
                ,
            },
            {
                title: '업로드 내용 등록',
                content:
                    <SoundThird form={this.props.form}/>
                ,
            },
        ];

        const contents = (
            <div>
                <Steps current={current} className='steps-line'>
                    {steps.map(item => (
                        <Step key={item.title} title={item.title}/>
                    ))}
                </Steps>
                {steps.map(({title, content}, i) => (
                    <div
                        key={title}
                        className={i === this.state.current ? "foo fade-in" : "foo"}
                    >
                        <div className="content-block">
                            {content}
                        </div>
                    </div>
                ))}
                <div className="steps-action">
                    {current > 0 && (
                        <Button style={{marginRight: 8}} onClick={() => this.prev()}>
                            이전
                        </Button>
                    )}
                    {current < steps.length - 1 && (
                        <Button type="primary" onClick={() => this.next()}>
                            다음
                        </Button>
                    )}
                    {current === steps.length - 1 && (
                        <Button type="primary" onClick={this.handleSubmit}>
                            업로드
                        </Button>
                    )}
                </div>
            </div>
        );

        const loginRequired = (
            <div>
                <Result
                    status="403"
                    title="로그인 필요"
                    subTitle="우측 상단의 로그인 버튼을 눌러 로그인하실 수 있습니다."
                />
            </div>
        );

        return (
            <div className='upload-main'>
                <div className='step-main'>
                    {this.props.isLoggedIn ? contents : loginRequired}
                    {this.state.uploadState && this.state.uploadState !== 'exception' ?
                        <div>
                            {/*<Progress type="circle" percent={75}/>*/}
                            {/*<Progress type="circle" percent={70} status="exception"/>*/}
                            <Progress percent={this.state.uploadProgress} status={this.uploadState}/>
                        </div>
                        :
                        null
                    }
                </div>
            </div>
        );
    }
}

UploadSound.propTypes = {
    isLoggedIn: PropTypes.bool
};

UploadSound.defaultProps = {
    isLoggedIn: false
};

export default withRouter(Form.create()(UploadSound));
