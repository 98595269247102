import React, {Component} from 'react';
import {Form, Upload, Row, Col, Input, Divider, Icon} from 'antd';
import {Editor} from '@tinymce/tinymce-react';

const {Dragger} = Upload;

// const uploadButton = (
//     <div>
//         <Icon type="plus"/>
//         <div className="upload-button-text">업로드</div>
//     </div>
// );

class BlogFirst extends Component {
    constructor(props) {
        super(props);
        // console.log('videofirst props', props);

        this.state = {
            backgroundImage: null
        };
        // this.player = videojs(this.videoNode, this.props, function onPlayerReady() {
        //     console.log('onPlayerReady', this)
        // });

    }

    handleSampleEditorChange = (e) => {
        this.props.form.setFieldsValue({sampleContent: e.target.getContent()})
        // console.log('Content was updated:', e.target.getContent());
    };

    handleEditorChange = (e) => {
        this.props.form.setFieldsValue({content: e.target.getContent()})
        // console.log('Content was updated:', e.target.getContent());
    };

    normFile = e => {
        let fileList = e.fileList.slice(-1);
        if (fileList.length === 0) {
            this.setState({albumArt: null});
        }
        if (Array.isArray(e)) {
            return e;
        }
        return e && fileList;
    };
    handleFileList = info => {
        console.log(this.props, info);
        console.log(this.props.form.getFieldValue('album_art'));

        let fileList = [...info.fileList];
        // this.props.form.setFieldsValue({
        //     album_art:[info.file]
        // });
        // this.setState({ art:[info.file] });
    };

    render() {
        const backgroundUploadProps = {
            action: '//jsonplaceholder.typicode.com/posts/',
            listType: 'picture-card',
            multiple: false,
            onChange: info => {
                const status = info.file.status;
                if (status !== 'uploading') {
                    console.log(info.file, info.fileList);
                }
            }
        };
        const {getFieldDecorator} = this.props.form;
        const draggerProps = {
            name: 'album_art',
            multiple: false,
            onChange: this.handleFileList,
        };

        return (
            <div
                style={{width: '100%', margin: '0 50px', maxWidth: 1280}}
            >
                <Row gutter={24} justify={'space-between'}>
                    <Col span={10} offset={7}>
                        {getFieldDecorator("background_image", {
                            valuePropName: 'fileList',
                            getValueFromEvent: this.normFile,
                        })(
                            <Dragger {...draggerProps}
                                     multiple={false}
                                     accept={'image/jpeg, image/png'}
                                     beforeUpload={(file) => {
                                         let reader = new FileReader();
                                         reader.readAsDataURL(file);
                                         reader.onloadend = function () {
                                             console.log(reader);
                                             this.setState({backgroundImage: reader.result});
                                             console.log(this);
                                         }.bind(this);
                                         return false;
                                     }}
                            >
                                {
                                    this.state.backgroundImage ?
                                        <div style={{
                                            marginBottom: 20,
                                            marginLeft: 'auto',
                                            marginRight: 'auto',
                                            border: '1px solid #d9d9d9',
                                            backgroundColor: '#f9f9f9',
                                            display: 'inline-block',
                                            position: 'relative',
                                            paddingBottom: '56.25%',
                                            width: '100%'
                                        }}>
                                            <img style={{
                                                objectFit: 'contain',
                                                position: 'absolute',

                                                width: '100%',
                                                height: '100%',
                                                top: '0',
                                                left: '0',
                                                bottom: '0',
                                                right: '0',
                                                // width: 640,
                                                // height: 360
                                            }} src={this.state.backgroundImage}/>
                                        </div>
                                        :
                                        <div>
                                            <p className="ant-upload-drag-icon">
                                                <Icon type="inbox"/>
                                            </p>
                                            <p className="ant-upload-text">배경이미지를 선택해주세요!</p>
                                            <p className="ant-upload-hint">
                                                최소 720x360, 16:9 권장
                                            </p>
                                        </div>

                                }
                            </Dragger>
                        )}
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Divider style={{paddingLeft:'50px',paddingRight:'50px'}}>블로그 내용 업로드</Divider>
                        <Form.Item>
                            {getFieldDecorator("title")(
                                <Input size="large" placeholder="제목"/>
                            )}
                        </Form.Item>

                        <Form.Item>
                            {getFieldDecorator("sampleContent")(
                                <div className={'text-field'}>
                                    <Editor
                                        apiKey={'t8bghhdw5hjmqrtgn6d3l21vwfsrexekho97t61ih29rbbyq'}
                                        style={{backgroundColor: '#fff', border: '1px solid #d9d9d9', minHeight: 300}}
                                        initialValue="<p>구매하지 않은 사람들에게도 보여줄 컨텐츠를 입력하세요!</p>"
                                        images_upload_url={'asmk'}
                                        automatic_uploads={false}

                                        init={{
                                            file_picker_types: 'image'
,
                                            resize: false,
                                            height: 6000,
                                            image_description: false,
                                            image_dimensions: false,
                                            menubar: false,
                                            images_upload_handler: function (blobInfo, success, failure) {
                                                let xhr, formData;
                                                xhr = new XMLHttpRequest();
                                                xhr.withCredentials = true;
                                                xhr.open('POST', 'https://api.swichee.me/content/static_upload?ver=0.2');
                                                xhr.onload = function() {
                                                    let json;

                                                    if (xhr.status !== 200) {
                                                        failure('HTTP Error: ' + xhr.status);
                                                        return;
                                                    }
                                                    json = JSON.parse(xhr.responseText);

                                                    if (!json || typeof json.url != 'string') {
                                                        failure('Invalid JSON: ' + xhr.responseText);
                                                        return;
                                                    }
                                                    console.log(json);
                                                    success(json.url,{alt:blobInfo.filename()});
                                                };
                                                formData = new FormData();
                                                formData.append('image', blobInfo.blob(), blobInfo.filename());
                                                xhr.send(formData);

                                                // setTimeout(function () {
                                                //     /* no matter what you upload, we will turn it into TinyMCE logo :)*/
                                                //     success('http://moxiecode.cachefly.net/tinymce/v9/images/logo.png');
                                                // }, 2000);
                                            },
                                            plugins: [
                                                'advlist autolink lists link image charmap print preview anchor',
                                                'searchreplace visualblocks code fullscreen',
                                                'insertdatetime media table paste code help wordcount'
                                            ],
                                            toolbar:
                                                'image | undo redo | formatselect | bold italic backcolor | \
                                                alignleft aligncenter alignright alignjustify | \
                                                bullist numlist outdent indent | removeformat | help'
                                        }}
                                        inline={true}
                                        onChange={this.handleSampleEditorChange}
                                    />
                                </div>
                            )}
                        </Form.Item>

                        <Form.Item>
                            {getFieldDecorator("content")(
                                <div className={'text-field'}>

                                    <Editor
                                        apiKey={'t8bghhdw5hjmqrtgn6d3l21vwfsrexekho97t61ih29rbbyq'}
                                        id={'myTextAreas'}

                                        style={{backgroundColor: '#fff', border: '1px solid #d9d9d9', minHeight: 300}}
                                        initialValue="<p>이제 구매한 사람들에게만 보여줄 컨텐츠를 입력하세요</p>"

                                        init={{
                                            file_picker_types: 'image'
                                            ,
                                            resize: false,
                                            height: 6000,
                                            image_description: false,
                                            image_dimensions: false,
                                            menubar: false,
                                            images_upload_handler: function (blobInfo, success, failure) {
                                                let xhr, formData;
                                                xhr = new XMLHttpRequest();
                                                xhr.withCredentials = true;
                                                xhr.open('POST', 'https://api.swichee.me/content/static_upload?ver=0.2');
                                                xhr.onload = function() {
                                                    let json;

                                                    if (xhr.status !== 200) {
                                                        failure('HTTP Error: ' + xhr.status);
                                                        return;
                                                    }
                                                    json = JSON.parse(xhr.responseText);

                                                    if (!json || typeof json.url != 'string') {
                                                        failure('Invalid JSON: ' + xhr.responseText);
                                                        return;
                                                    }
                                                    console.log(json);
                                                    success(json.url,{alt:blobInfo.filename()});
                                                };
                                                formData = new FormData();
                                                formData.append('image', blobInfo.blob(), blobInfo.filename());
                                                xhr.send(formData);

                                                // setTimeout(function () {
                                                //     /* no matter what you upload, we will turn it into TinyMCE logo :)*/
                                                //     success('http://moxiecode.cachefly.net/tinymce/v9/images/logo.png');
                                                // }, 2000);
                                            },
                                            plugins: [
                                                'advlist autolink lists link image charmap print preview anchor',
                                                'searchreplace visualblocks code fullscreen',
                                                'insertdatetime media table paste code help wordcount'
                                            ],
                                            toolbar:
                                                'image | undo redo | formatselect | bold italic backcolor | \
                                                alignleft aligncenter alignright alignjustify | \
                                                bullist numlist outdent indent | removeformat | help'
                                        }}
                                        inline={true}
                                        onChange={this.handleEditorChange}
                                    />
                                </div>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        )
            ;
    }
}

export default Form.create()(BlogFirst);
