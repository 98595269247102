import React, {Component} from 'react';
import {List, Avatar, Button, Skeleton, Tag} from 'antd';
import axios from "axios";
import {Table, Icon, Switch, Radio, Form, Divider} from 'antd';

import './queue-style.css';

class UploadQueue extends Component {

    constructor(props) {
        super(props);
        this.state = {
            initLoading: true,
            loading: false,
            data: [],
            list: [],
            limit: 0,
            size: 10
        };
    }

    componentDidMount() {
        this.getData(res => {
            console.log(res);
            this.setState({
                initLoading: false,
                data: res,
                list: res,
                limit:res.length
            });
        });
    }

    getData = callback => {
        console.log('post limit',this.state.limit);
        axios.post('https://sandbox.swichee.me/content/upload_queue',
            {
                limit:this.state.limit,
                size:this.state.size
            },
            {withCredentials: true},)
            .then(value => {
                console.log(value.data);
                let data = value.data.data;
                for (let i = 0; i < data.length; i++) {
                    let niddle = this.state.list.findIndex(row=> row.queue_idx === data[i].queue_idx);
                    //console.log('i',i,niddle);
                    if(niddle!==-1){
                        data.splice(i,1);
                        i--;
                    }
                }
                // for (let i = 0; i < data.length; i++) {
                //     let niddle = this.state.data.find(row=> row.queue_idx === data.queue_idx);
                //     data.splice(niddle,niddle);
                // }
                callback(data)
            });
        // reqwest({
        //     url: fakeDataUrl,
        //     type: 'json',
        //     method: 'get',
        //     contentType: 'application/json',
        //     success: res => {
        //         callback(res);
        //     },
        // });
    };

    onLoadMore = () => {
        console.log(this.state.data);
        this.setState({
            loading: true,
            list: this.state.data.concat([...new Array(3)].map(() => ({loading: true, name: {}}))),
        });
        this.getData(res => {
            const data = this.state.data.concat(res);
            this.setState(
                {
                    data,
                    list: data,
                    loading: false,
                    limit:data.length
                },
                () => {
                    // Resetting window's offsetTop so as to display react-virtualized demo underfloor.
                    // In real scene, you can using public method of react-virtualized:
                    // https://stackoverflow.com/questions/46700726/how-to-use-public-method-updateposition-of-react-virtualized
                    window.dispatchEvent(new Event('resize'));
                },
            );
            console.log('limit',this.state.limit);
        });
    };

    contentTypeRender = (contentType) => {

        switch (contentType) {
            case 'photo':
                return "magenta";
            case 'blog':
                return "orange";
            case 'video':
                return "green";
            case 'sound':
                return "geekblue";
        }
    };

    progressStateRender = (contentType) => {

        switch (contentType) {
            case 'complete':
                return "#87d068";
            case 'error':
                return "#f00";
            case 'started':
                return "#2db7f5";
            case 'encoding':
                return "#108ee9";
        }
    };

    render() {
        const {initLoading, loading, list} = this.state;

        const loadMore =
            !initLoading && !loading ? (
                <div
                    style={{
                        textAlign: 'center',
                        marginTop: 12,
                        height: 32,
                        lineHeight: '32px',
                    }}
                >
                    <Button onClick={this.onLoadMore}>More</Button>
                </div>
            ) : null;
        return (
            <div style={{margin: "50px auto", maxWidth: 1400}}>
                <div className="queue-block">
                    <List
                        className="demo-loadmore-list"
                        loading={initLoading}
                        itemLayout="horizontal"
                        loadMore={loadMore}
                        dataSource={list}
                        renderItem={item => (
                            <List.Item
                                // actions={[<a key="list-loadmore-edit">edit</a>, <a key="list-loadmore-more">more</a>]}
                            >
                                <Skeleton title={false} loading={item.loading} active>
                                    <List.Item.Meta
                                        title={item.title === "" ? (<>&nbsp;</>) : item.title}
                                        description={new Date(item.insert_dt + ' UTC').toLocaleString()}
                                        // description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                                    />
                                    <div>
                                        <Tag color={this.contentTypeRender(item.content_type)}>{item.content_type}</Tag>
                                        <Tag
                                            color={this.progressStateRender(item.progress_state)}>{item.progress_state}</Tag>
                                    </div>
                                </Skeleton>
                            </List.Item>
                        )}
                    />
                </div>
            </div>
        );
    }
}

export default UploadQueue;
