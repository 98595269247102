import React, {Component} from 'react';
import {Divider, Form, Icon, Upload, Row, Col, Modal, Input, Popconfirm, message, Tooltip} from "antd";

const {TextArea} = Input;
const uploadButton = (
    <div style={{float: 'none'}}>
        <Icon type="plus"/>
        <div className="upload-button-text">업로드</div>
    </div>
);

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

class ImageFirst extends Component {
    constructor(props) {
        super(props);

        this.state = {
            previewVisible: false,
            previewImage: '',
            swicheeOffImageList: {
                fileList: [],
            },
            swicheeOnImageList: {
                fileList: [],
            },
        };
    }

    // state = {
    //     previewVisible: false,
    //     previewImage: '',
    //     swicheeOffImageList: [],
    //     swicheeOnImageList: [],
    // };

    handleCancel = () => {
        this.setState({previewVisible: false});
    };

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
        });
    };

    handleOffChange = (swicheeOffImageList) => this.setState({swicheeOffImageList});
    handleOnChange = (swicheeOnImageList) => {
        // console.log('if문 전', swicheeOnImageList);
        if (swicheeOnImageList.file.size > 10000 && swicheeOnImageList.file.status !== 'error') {
            // console.log('if문 안으로 들어왔습니다', swicheeOnImageList);
            // swicheeOnImageList.file.status = 'error';
        } else {
            // console.log('else문에 들어왔습니다', swicheeOnImageList);
            this.setState({swicheeOnImageList});
        }
        console.log('if문 후', swicheeOnImageList);
    };

    normFile = e => {
        console.log(e);
        if (e.fileList.length > 30) {
            return e && e.fileList.slice(e.fileList.length - 30);
        }
        return e && e.fileList;
    };

    confirm(e) {
        console.log(e);
        message.success('Click on Yes');
    }

    cancel(e) {
        console.log(e);
        message.error('Click on No');
    }

    validateStep () {
        if(!this.props.form.getFieldValue('image_elec') || this.props.form.getFieldValue('image_elec').length===0){
            message.error('이미지 업로드파일을 체크해주세요');
            return false;
        }
        return true;
        // return !!this.props.form.getFieldValue('image_elec') && this.props.form.getFieldValue('image_elec').length!==0;
    }

    render() {
        const swicheeOffUploadProps = {
            action: '//jsonplaceholder.typicode.com/posts/',
            listType: 'picture-card',
            multiple: true,
            // defaultFileList: this.state.swicheeOffImageList.fileList,
            // onChange: this.handleOffChange,
            onPreview: this.handlePreview,
            accept: '.png,.jpg,.gif,.jpeg',

        };
        const swicheeOnUploadProps = {
            action: 'https://www.mocky.io/v2/5185415ba171ea3a00704eed',
            listType: 'picture-card',
            multiple: true,
            // defaultFileList: this.state.swicheeOnImageList.fileList,
            // onChange: this.handleOnChange,
            onPreview: this.handlePreview,
            accept: '.png,.jpg,.gif,.jpeg',

        };
        const {getFieldDecorator} = this.props.form;
        return (
            <div
                style={{width: '100%', margin: '0 50px'}}
            >
                <Row gutter={24}>
                    <Col span={12}>
                        <Divider>Swichee Off</Divider>
                        <div style={{textAlign: 'right'}}>
                            <Tooltip title={renderProps => (
                                <div style={{textAlign:'center'}}>구매하지 않아도 볼수있는 컨텐츠 입니다. <br/>최대 30개</div>
                            )}>
                                <Icon type="question-circle-o" style={{color: '#f90', marginRight: 30}}/>
                            </Tooltip>
                        </div>
                        <Form.Item>
                            {getFieldDecorator("image_free", {
                                valuePropName: 'fileList',
                                getValueFromEvent: this.normFile,
                            })(
                                <Upload {...swicheeOffUploadProps}
                                        beforeUpload={(file) => {
                                            // let reader = new FileReader();
                                            // reader.readAsDataURL(file);
                                            // reader.onloadend = function () {
                                            //     console.log(reader);
                                            //     // this.player.src({type: file.type, src: reader.result});
                                            //     this.setState({albumArt:reader.result});
                                            //     console.log(this);
                                            // }.bind(this);
                                            return false;
                                        }}
                                >
                                    {uploadButton}
                                </Upload>
                            )}
                        </Form.Item>

                    </Col>
                    <Col span={12}>
                        <Divider>Swichee On</Divider>
                        <div style={{textAlign: 'right'}}>
                            <Tooltip title={renderProps => (
                                <div style={{textAlign:'center'}}>구매후에 볼수있는 컨텐츠 입니다. <br/>최대 30개</div>
                            )}>
                                <Icon type="question-circle-o" style={{color: '#f90', marginRight: 30}}/>
                            </Tooltip>
                        </div>
                        <Form.Item>
                            {getFieldDecorator("image_elec", {
                                rules: [
                                    {
                                        required: true,
                                        message: "이미지를 업로드해주세요!",
                                    },
                                ],
                                valuePropName: 'fileList',
                                getValueFromEvent: this.normFile,
                            })(
                                <Upload {...swicheeOnUploadProps}
                                        beforeUpload={(file) => {
                                            // let reader = new FileReader();
                                            // reader.readAsDataURL(file);
                                            // reader.onloadend = function () {
                                            //     console.log(reader);
                                            //     // this.player.src({type: file.type, src: reader.result});
                                            //     this.setState({albumArt: reader.result});
                                            //     console.log(this);
                                            // }.bind(this);
                                            return false;
                                        }}
                                >
                                    {this.state.swicheeOnImageList.fileList.length >= 8 ? null : uploadButton}
                                </Upload>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Divider>내용 입력</Divider>
                        <Form.Item>
                            {getFieldDecorator("title")(
                                <Input rows={6} placeholder="제목을 입력해주세요"/>
                            )}
                        </Form.Item>
                        <Form.Item>
                            {getFieldDecorator("content")(
                                <TextArea rows={6} placeholder="컨텐츠와 함게 들어갈 내용을 추가해보세요"/>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Modal visible={this.state.previewVisible} footer={null} onCancel={this.handleCancel}>
                    <img alt="example" style={{width: '100%'}} src={this.state.previewImage}/>
                </Modal>
            </div>
        );
    }
}

const ImageFirstForm = Form.create({})(ImageFirst);

export default ImageFirstForm;
