import React from 'react'

export default ({active, color, size, backgroundColor, style}) => (
    <span style={{
        // marginRight: 10,
        display: 'inline-flex',
        alignItems: 'center',
        textAlign: 'center',
        MozBorderRadius: '100px', WebkitBorderRadius: '100px', borderRadius: '100px',
        width: size,
        height: size,
        ...style
        // padding: 10, borderRadius: 2
    }}>
        {/*<svg width={size} height={size} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.5879 52.4512"*/}
        {/*     style={{margin: '0 auto'}}>*/}
        {/*    <polygon*/}
        {/*        points="15.133 52.451 24.565 52.451 42.588 17.34 20.12 17.34 24.776 0 18.698 0 0 32.814 20.532 32.814 15.133 52.451"*/}
        {/*        // style="fill:#f90"*/}
        {/*        fill={color}*/}
        {/*    />*/}
        {/*</svg>*/}
        <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 61.1782 61.1782">
            <defs>
                <clipPath id="clipping">
                    <circle cx="30.5863" cy="30.5863" r="30.5863"/>
                </clipPath>
            </defs>
            <rect x="0.0027" y="0.0034" width="61.1726" height="61.17115" fill={backgroundColor} clipPath="url(#clipping)"/>
            <polygon
                points="24.819 55.153 33.653 55.153 50.534 22.265 29.489 22.265 33.85 6.023 28.158 6.023 10.644 36.76 29.875 36.76 24.819 55.153"
                fill={color}
            />
        </svg>
    </span>
)
