import * as types from '../actions/ActionTypes';
import axios from 'axios';
import {getStatusFailure, getUserStatus} from "../actions/authentication";
import Cookies from "universal-cookie";
let cookies = new Cookies();
const initialState = {
    login: {
        status: !cookies.get('pr_nn')?'INIT':'WAITING'
    },
    status: {
        valid: false,
        isLoggedIn: !!cookies.get('pr_nn'),
        currentUser: ''
    },
    data:{
        image:cookies.get('pr_img'),
        coin:'',
        profit:'',
        blue_check:'0',
        has_follower:'',
        nickname:cookies.get('pr_nn'),
        name:cookies.get('pr_n'),
    }
};

export default function authentication(state=initialState, action) {
    console.log('action check',state,action);
    switch(action.type) {
        case types.AUTH_LOGIN:
            return {
                ...state,
                login: {
                    status: 'WAITING'
                }
            };
        case types.AUTH_LOGIN_SUCCESS:
            return {
                ...state,
                login: {
                    status: 'SUCCESS'
                },
                status: {
                    ...state.status,
                    isLoggedIn: true,
                    currentUser: action.username
                },
                // data: action.data
            };
        case types.AUTH_LOGIN_FAILURE:
            return {
                ...state,
                login: {
                    status: 'FAILURE'
                }
            };
        case types.AUTH_GET_STATUS:
            return {
                ...state,
                status: {
                    ...state.status,
                    isLoggedIn: true
                },
                // data: action.data
            };
        case types.AUTH_GET_STATUS_SUCCESS:
            return {
                ...state,
                login: {
                    status: 'SUCCESS'
                },
                status: {
                    ...state.status,
                    valid: true,
                    currentUser: action.username,
                    isLoggedIn: true
                },
                data: action.data
            };
        case types.AUTH_GET_STATUS_FAILURE:
            return {
                ...state,
                status: {
                    ...state.status,
                    valid: false,
                    isLoggedIn: false
                }
            };
        case types.AUTH_LOGOUT:
            return {
                ...state,
                status: {
                    ...state.status,
                    isLoggedIn: false,
                    currentUser: ''
                }
            };
        default:
            return state;
    }
};
