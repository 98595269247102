import React from 'react';
import {Anchor, Row, Col, Divider} from 'antd';
import './Home.css';

const {Link} = Anchor;

const Home = () => {
    return (
        <div style={{minWidth: 1240}}>
            <div style={{
                position: "relative",
                width: "100%",
                paddingTop: "42.8571%"
            }}>
                <div className="card-color-home" style={{
                    backgroundImage: `url("main 복사본.png")`,
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0
                }}>
                    <h1 id="title">
                        창작자를 위한
                        <br/>
                        <b>
                            새로운 세계
                        </b>
                    </h1>
                    {/*    <span id="content">*/}
                    {/*        Innumerable artists' soul unites in Swichee*/}
                    {/*</span>*/}
                </div>
            </div>
            <div className="card-color-home-sub">
                <div id="title">
                    새로운 세계를 우리 함께 만들어가요
                </div>
                <div id="content">
                    스위치는 컨텐츠를 사랑하는 사람이라면 누구나 함께할 수 있는 창작자를 위한 새로운 세계 입니다.
                    <br/>
                    스위치에서는 창작자들이 직접 만든 컨텐츠들을 공유하고 감상하며 수익을 창출할 수 있습니다.
                </div>
            </div>
            <div className={"acmenu"}
                 style={{backgroundColor: `rgb(255, 255, 255)`, padding: `100px 0`, margin: `0 auto`}}>

                <Row gutter={24}>
                    <Col span={2}/>
                    <Col span={5}>
                        <Anchor>
                            <Link href="#submenu_1" className={'anchor_title'} title={<b>간편한 회원가입</b>}/>
                            <Link href="#submenu_2" className={'anchor_title'} title={<b>스위치 ON / OFF</b>}/>
                            <Link href="#submenu_3" className={'anchor_title'} title={<b>일렉</b>}/>
                            <Link href="#submenu_4" className={'anchor_title'} title={<b>업로드</b>}/>
                            <Link href="#submenu_5" className={'anchor_title'} title={<b>폴더 기능</b>}/>
                            {/*<Link href="#submenu_6" className={'anchor_title'} title={<b>PC 에서도 업로드 가능</b>}/>*/}
                            <Link href="#submenu_7" className={'anchor_title'} title={<b>공유하기</b>}/>
                            <Link href="#submenu_8" className={'anchor_title'} title={<b>수익</b>}/>

                        </Anchor>
                    </Col>
                    <Col span={14}>
                        <div className="submenu" id="submenu_1"
                             style={{
                                 minHeight: 720,
                                 // maxWidth: 800, maxHeight: 450
                             }}
                        >
                            <h2 style={{fontSize:`2.5em`}}>
                                간편한 회원가입
                            </h2>

                            <div style={{
                                position: "relative",
                                width: "100%",
                                paddingTop: "56.25%",
                                backgroundColor: `#f90`,
                                maxHeight:540,
                                minHeight:450,
                            }}
                            >

                                <div style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                }}>
                                    <img src={`main_submenu1_01.png`} alt={''}
                                         style={{width: `50%`, height: `100%`, objectFit: `scale-down`, padding: 60}}>
                                    </img>
                                    <img src={`main_submenu1_02.png`} alt={''}
                                         style={{width: `50%`, height: `100%`, objectFit: `scale-down`, padding: 60}}>
                                    </img>
                                </div>
                            </div>

                            <p style={{margin:`30px 0`}}>
                                스위치의 회원가입은 너무 쉽습니다.
                                <br/>
                                Facebook 이나 Google 계정으로도 쉽고 빠르게 시작할 수 있습니다.
                                <br/>
                                다운로드부터 반가운 홈화면을 만날때까지 1분이면 충분합니다.
                                <br/>
                            </p>
                        </div>
                        <Divider/>
                        <div className="submenu" id="submenu_2"
                             style={{
                                 minHeight: 720,
                                 // maxWidth: 800, maxHeight: 450
                             }}
                        >
                            <h2 style={{fontSize:`2.5em`}}>
                                스위치 ON / OFF
                            </h2>
                            <div style={{
                                position: "relative",
                                width: "100%",
                                paddingTop: "56.25%",
                                backgroundColor: `#f90`
                            }}
                            >

                                <div style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                }}>
                                    <img src={`9.png`} alt={''}
                                         style={{width: `50%`, height: `100%`, objectFit: `scale-down`, padding: 60}}>
                                    </img>
                                    <img src={`10.png`} alt={''}
                                         style={{width: `50%`, height: `100%`, objectFit: `scale-down`, padding: 60}}>
                                    </img>
                                </div>
                            </div>
                            <p style={{margin:`30px 0`}}>
                                모든 컨텐츠들은 스위치 ON 이거나 스위치 OFF 둘중 한가지 상태에 있어요.
                                <br/>
                                스위치 OFF 인 경우 컨텐츠의 일부만 볼 수 있어요.
                                <br/>
                                스위치 ON 인 컨텐츠의 경우 컨텐츠의 전체를 다 볼 수 있어요.
                                <br/>
                            </p>
                        </div>
                        <Divider/>
                        <div
                            className="submenu" id="submenu_3"
                            style={{
                                minHeight: 720,
                            }}
                        >
                            <h2 style={{fontSize:`2.5em`}}>
                                일렉
                            </h2>
                            <div style={{
                                position: "relative",
                                width: "100%",
                                paddingTop: "56.25%",
                                backgroundColor: `#f90`
                            }}
                            >

                                <div style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                }}>
                                    <img src={`main_submenu1_01.png`} alt={''}
                                         style={{width: `50%`, height: `100%`, objectFit: `scale-down`, padding: 60}}>
                                    </img>
                                    <img src={`main_submenu1_02.png`} alt={''}
                                         style={{width: `50%`, height: `100%`, objectFit: `scale-down`, padding: 60}}>
                                    </img>
                                </div>
                            </div>
                            <p style={{margin:`30px 0`}}>
                                스위치 서비스에서 사용되는 화폐를 우리는 일렉이라 불러요.
                                <br/>
                                스위치 OFF 인 컨텐츠를 스위치 ON 시키기 위해서는 크리에이터가 정해놓은 만큼의 일렉이 필요해요.
                                <br/>
                                일렉은 홈 탭 우측 상단에 나의지갑 메뉴에서 확인할 수 있고 쉽게 충전할 수 있어요.
                                <br/>
                            </p>
                        </div>
                        <Divider/>
                        <div
                            className="submenu" id="submenu_4"
                            style={{
                                minHeight: 720,
                            }}
                        >
                            <h2 style={{fontSize:`2.5em`}}>
                                업로드
                            </h2>
                            <div style={{
                                position: "relative",
                                width: "100%",
                                paddingTop: "56.25%",
                                backgroundColor: `#f90`
                            }}
                            >

                                <div style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                }}>
                                    <img src={`11.png`} alt={''}
                                         style={{width: `50%`, height: `100%`, objectFit: `scale-down`, padding: 60}}>
                                    </img>
                                    <img src={`12.png`} alt={''}
                                         style={{width: `50%`, height: `100%`, objectFit: `scale-down`, padding: 60}}>
                                    </img>
                                </div>
                            </div>
                            <p style={{margin:`30px 0`}}>
                                스위치에서는 내가 만든 모든 컨텐츠를 쉽고 빠르게 업로드하여 수익을 만들 수 있어요.
                                <br/>
                                스위치 OFF 인 컨텐츠를 스위치 ON 시키기 위해서는 크리에이터가 정해놓은 만큼의 일렉이 필요해요.
                                <br/>
                                최소 0원에서 최대 500000원 까지 컨텐츠의 가격을 원하는대로 설정해 보세요.
                                <br/>
                                동영상이나 사진, 오디오, 블로그 등 원하는 형태의 컨텐츠 모두 업로드 가능합니다.
                                <br/>
                            </p>
                        </div>
                        <Divider/>
                        <div
                            className="submenu" id="submenu_5"
                            style={{
                                minHeight: 720,
                            }}
                        >
                            <h2 style={{fontSize:`2.5em`}}>
                                폴더 기능
                            </h2>
                            <div style={{
                                position: "relative",
                                width: "100%",
                                paddingTop: "56.25%",
                                backgroundColor: `#f90`
                            }}
                            >

                                <div style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                }}>
                                    <img src={`7.png`} alt={''}
                                         style={{width: `50%`, height: `100%`, objectFit: `scale-down`, padding: 60}}>
                                    </img>
                                    <img src={`8.png`} alt={''}
                                         style={{width: `50%`, height: `100%`, objectFit: `scale-down`, padding: 60}}>
                                    </img>
                                </div>
                            </div>
                            <p style={{margin:`30px 0`}}>
                                스위치의 장점! 폴더기능을 소개합니다.
                                <br/>
                                프로필 탭에서 자유롭게 폴더설정을 하고 쉽게 컨텐츠를 분류하고 관리해 보세요.
                                <br/>
                                누구나 볼 수 있는 전체공개 폴더, 오직 팔로워만 볼 수 있는 팔로워공개 폴더, 가장 요긴하게 사용할 수 있는 비밀번호 공개폴더 까지
                                <br/>
                                스위치에서는 원하는 모든 방향의 컨텐츠 공개가 가능합니다.
                            </p>
                        </div>
                        <Divider/>
                        {/*<div*/}
                        {/*    className="submenu" id="submenu_6"*/}
                        {/*    style={{*/}
                        {/*        minHeight: 720,*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    <h2 style={{fontSize:`2.5em`}}>*/}
                        {/*        PC 에서도 업로드 가능*/}
                        {/*    </h2>*/}
                        {/*    <div style={{*/}
                        {/*        position: "relative",*/}
                        {/*        width: "100%",*/}
                        {/*        paddingTop: "56.25%",*/}
                        {/*        backgroundColor: `#f90`*/}
                        {/*    }}*/}
                        {/*    >*/}

                        {/*        <div style={{*/}
                        {/*            position: "absolute",*/}
                        {/*            top: 0,*/}
                        {/*            left: 0,*/}
                        {/*            right: 0,*/}
                        {/*            bottom: 0,*/}
                        {/*        }}>*/}
                        {/*            <img src={`main_submenu1_01.png`} alt={''}*/}
                        {/*                 style={{width: `50%`, height: `100%`, objectFit: `scale-down`, padding: 60}}>*/}
                        {/*            </img>*/}
                        {/*            <img src={`main_submenu1_02.png`} alt={''}*/}
                        {/*                 style={{width: `50%`, height: `100%`, objectFit: `scale-down`, padding: 60}}>*/}
                        {/*            </img>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <p style={{margin:`30px 0`}}>*/}
                        {/*        혹시 PC 에 업로드 하고싶은 컨텐츠가 있나요?*/}
                        {/*        <br/>*/}
                        {/*        PC에서 스위치 업로드 센터에 접속해보세요.*/}
                        {/*        <br/>*/}
                        {/*        편하고 빠르게 PC 에서 업로드 할 수 있습니다.*/}
                        {/*    </p>*/}
                        {/*</div>*/}
                        {/*<Divider/>*/}
                        <div
                            className="submenu" id="submenu_7"
                            style={{
                                minHeight: 720,
                            }}
                        >
                            <h2 style={{fontSize:`2.5em`}}>
                                공유하기
                            </h2>
                            <div style={{
                                position: "relative",
                                width: "100%",
                                paddingTop: "56.25%",
                                backgroundColor: `#f90`
                            }}
                            >

                                <div style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                }}>
                                    <img src={`5.png`} alt={''}
                                         style={{width: `50%`, height: `100%`, objectFit: `scale-down`, padding: 60}}>
                                    </img>
                                    <img src={`6.png`} alt={''}
                                         style={{width: `50%`, height: `100%`, objectFit: `scale-down`, padding: 60}}>
                                    </img>
                                </div>
                            </div>
                            <p style={{margin:`30px 0`}}>
                                내가 만든 멋진 컨텐츠를 자랑하고 싶나요?
                                <br/>
                                스위치 에서는 쉽고 빠르게 공유할 수 있어요.
                                <br/>
                                컨텐츠 플레이어 혹은 프로필 탭에서 추가메뉴를 눌러 URL 링크를 복사해 보세요.
                                <br/>
                                내가 자주 사용하는 SNS 나 메신저에 붙여넣기하면 홍보 끝!
                            </p>
                        </div>
                        <Divider/>
                        <div
                            className="submenu" id="submenu_8"
                            style={{
                                minHeight: 720,
                            }}
                        >
                            <h2 style={{fontSize:`2.5em`}}>
                                수익
                            </h2>
                            <div style={{
                                position: "relative",
                                width: "100%",
                                paddingTop: "56.25%",
                                backgroundColor: `#f90`
                            }}
                            >

                                <div style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                }}>
                                    <img src={`main_submenu1_01.png`} alt={''}
                                         style={{width: `50%`, height: `100%`, objectFit: `scale-down`, padding: 60}}>
                                    </img>
                                    <img src={`main_submenu1_02.png`} alt={''}
                                         style={{width: `50%`, height: `100%`, objectFit: `scale-down`, padding: 60}}>
                                    </img>
                                </div>
                            </div>
                            <p style={{margin:`30px 0`}}>
                                수익창출은 스위치의 가장 핵심되는 기능입니다.
                                <br/>
                                누구의 도움도 받지 않고 내 힘으로 원하는 만큼의 수익을 만들어 보세요.
                                <br/>
                                내가 만든 내 수익을 출금하여 나의 꿈에 재투자 해보세요.
                            </p>
                        </div>
                    </Col>
                    <Col span={3}/>

                </Row>
            </div>
        </div>
    );
};

export default Home;
